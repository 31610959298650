import { z } from 'zod'
import { apiRequest } from './apiRequest'
import {
    YouTubeGamesResponseBody,
    YouTubeReportRequestBody,
    YouTubeTagsResponseBody,
    YouTubeTopicCategoriesResponseBody,
    GetStacksMetricsResponseBody,
    youTubeGamesResponseBody,
    youTubeReportRequestBodySchema,
    youTubeTagsResponseBody,
    youTubeTopicCategoriesResponseBody,
    tiktokFiltersResponseBodySchema,
    TiktokFiltersResponseBody,
    tiktokReportRequestBodySchema,
    TiktokReportRequestBody,
    getStacksMetricsResponseSchema,
} from './models'

const CreatorApiPaths = {
    searchYouTubeGames: 'youtube-games',
    searchYouTubeTopicCategories: 'youtube-topic-categories',
    searchYouTubeTags: 'youtube-tags',
    createYouTubeCreatorsReport: 'creators-report-youtube',
    getStackMetrics: 'get-stack-metrics',
    getTiktokFilters: 'tiktok-filters',
    createTiktokCreatorsReport: 'creators-report-tiktok',
}

type GetMetricsRequestFilter = {
    startAt: string | null
    endAt: string | null
}

export abstract class CreatorApi {
    static async searchYouTubeGames(
        gameName: string
    ): Promise<YouTubeGamesResponseBody> {
        return apiRequest({
            payload: {
                method: 'POST',
                body: { name: gameName },
                url: CreatorApiPaths.searchYouTubeGames,
            },
            responseSchema: youTubeGamesResponseBody,
        })
    }

    static async searchYouTubeTopicCategories(
        topic: string
    ): Promise<YouTubeTopicCategoriesResponseBody> {
        return apiRequest({
            payload: {
                method: 'POST',
                body: { name: topic },
                url: CreatorApiPaths.searchYouTubeTopicCategories,
            },
            responseSchema: youTubeTopicCategoriesResponseBody,
        })
    }

    static async searchYouTubeTags(
        tag: string
    ): Promise<YouTubeTagsResponseBody> {
        return apiRequest({
            payload: {
                method: 'POST',
                body: { name: tag },
                url: CreatorApiPaths.searchYouTubeTags,
            },
            responseSchema: youTubeTagsResponseBody,
        })
    }

    static async requestCreatorsYouTubeReport(
        body: YouTubeReportRequestBody
    ): Promise<void> {
        youTubeReportRequestBodySchema.parse(body)

        await apiRequest({
            payload: {
                method: 'POST',
                body,
                url: CreatorApiPaths.createYouTubeCreatorsReport,
            },
            responseSchema: z.object({}),
        })
    }

    static async getMetrics(
        filters: GetMetricsRequestFilter
    ): Promise<GetStacksMetricsResponseBody> {
        return apiRequest({
            payload: {
                method: 'POST',
                body: filters,
                url: CreatorApiPaths.getStackMetrics,
            },
            responseSchema: getStacksMetricsResponseSchema,
        })
    }

    static async getTiktokFilters(): Promise<TiktokFiltersResponseBody> {
        return apiRequest({
            payload: {
                method: 'POST',
                body: {},
                url: CreatorApiPaths.getTiktokFilters,
            },
            responseSchema: tiktokFiltersResponseBodySchema,
        })
    }

    static async requestCreatorsTiktokReport(
        body: TiktokReportRequestBody
    ): Promise<void> {
        tiktokReportRequestBodySchema.parse(body)

        await apiRequest({
            payload: {
                method: 'POST',
                body,
                url: CreatorApiPaths.createTiktokCreatorsReport,
            },
            responseSchema: z.object({}),
        })
    }
}
